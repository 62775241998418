import React from 'react';

export function CalendarIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			width="32"
			height="32"
			fill="none"
			stroke="currentcolor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2">
			<path d="M2 6 L2 30 30 30 30 6 Z M2 15 L30 15 M7 3 L7 9 M13 3 L13 9 M19 3 L19 9 M25 3 L25 9" />
		</svg>
	);
}
